import { useQuery } from '@tanstack/react-query';
import { fetchConfig } from '@/lib/fetchConfig';

export const queryExpertiseSubpages = `query {
    categoryPageCollection(limit: 100, where: {categoryParent: "Expertise"}){
        __typename 
        items{
          ...on CategoryPage{
            slug 
            pageName
          } 
        } 
    } 
}`;

export const queryExpertiseSubpage = `query($locale: String!, $slug: String! ) {
    categoryPageCollection(limit: 1, locale: $locale, where: { slug: $slug}){
        items{
            ...on CategoryPage{
              pageName
              titleDescription{
                json
              }
              titleLinksCollection {
                __typename
                items {
                  ... on NavigationElement {
                    __typename
                    title
                    urlPath
                  }
                }
              }
            }
            contentCollection {
              __typename
              items {
                ... on Quote {
                  quoteText
                  author
                  authorTitle
                }
               ... on ArticleSection {
            __typename
            sectionPadding
            sys {
              id
            }
            title
            layout
            numberOfLoadedContent
            sectionContentCollection(limit: 10) {
              items {
                ... on Article {
                      __typename
                  featuredTitle
                  featuredDescription{
                    json
                  }
                  slug
                  category
                  featuredImage {
                    width
                    height
                    url
                    title
                  }
                }
              }
            }
          }
                ... on Quote{
                  __typename
                  quoteText
                  author
                  authorTitle
                }
                ... on CardSection {
                  __typename
                  internalTitle
                  sectionPadding
                  layout
                  title
                  sectionContentCollection(limit: 20) {
                    items {
                      ... on Card {
                        __typename
                        shortDescription {
                          json
                        }
                        onlyImagevideo
                        cardTemplate
                        linkUrl
                        title
                        internalTitle
                        linkIconTitle
                        videoUrl
                      	linkMailto
                        cardImage{
                            width
                            height
                            url
                            title
                          }
                        icon {
                            url
                            title
                            width
                            height
                          }
                        }
                      ... on Accordion{
                        __typename
                        title
                        description{
                          json
                          }
                        }
                      }
                    }
                  }
                
                  ...on AwardsAndCertificates {
                    __typename
                    title
                    imagesCollection {
                      items {
                        title
                        description
                        url
                        size
                      }
                    }
                  }
                  ...on PersonBanner{
                    __typename
                    sys{
                      id
                    }
                    internalTitle
                    quote
                    personName
                    picturePosition
                    pictureOfPerson{
                      title
                      description
                      width
                      height
                      url
                    }
                    linkedIn{
                      urlPath
                      title
                      document{
                        title
                        description
                        url
                        width
                        height
                      }
                    }
                    personName
                    personPosition
                    personMail
                  }
                }
              }
              containerForCustomButton {
                ... on NavigationButton {
                  __typename
                  internalTitle
                  buttonText
                  buttonUrl
                }
              }
              seo {
                title
                description
                canonicalUrl
                nextPage
                noindex
                nofollow
                image {
                  url
                  width
                  height
                  title
                  description
                }
                ldType
                ldAuthor
                ldAuthorUrl
                ldPublished
                ldUpdated
              }
            } 
          } 
        }`;

const fetchExpertiseSubpage = async (locale = 'de', slug = '') => {
  const variables = { locale, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryExpertiseSubpage, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];
    throw new Error(message);
  }
  return json.data;
};

const useExpertiseSubpage = (locale: any, slug: any) => {
  return useQuery({
    queryKey: [slug, locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchExpertiseSubpage(locale, slug)
  });
};

export { useExpertiseSubpage, fetchExpertiseSubpage };
