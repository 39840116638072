import { useQuery } from '@tanstack/react-query';
import { fetchConfig } from '@/lib/fetchConfig';

export const queryFooter = `query ($locale: String!){
  footerCollection(limit:1, locale: $locale){
    __typename
     items{
       __typename
       ...on Footer{
         title
         socialLinksCollection{
           ...on FooterSocialLinksCollection{
             __typename
             items{
               ...on NavigationElement{
                 title
                 urlPath
                 document{
                   title
                   url
                   width
                   height
                 }
               }
             }
           }
         }
         footerNavigationCollection(limit:20){
           ...on FooterFooterNavigationCollection{
             __typename
             items{
               ...on NavigationElement{
                 title
                 urlPath
                 document{
                  title
                  description
                  url
                  width
                }
               }
             }
           }
         }
         locationsCollection(limit: 20){
           ...on FooterLocationsCollection{
             items{
               ...on NavigationElement{
                 title
                 urlPath
               }
             }
           }
         }
         consulteer
         backToTop{
             ...on NavigationElement{
               sys{
                 id
               }
               title
               urlPath
               document{
                 title
                 url
                 width
                 height
               }
             }
         }
         }
       }
     }
}`;

const fetchFooterData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryFooter, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useFooterData = (locale: any) => {
  return useQuery({
    queryKey: ['footer', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchFooterData(locale)
  });
};

export { useFooterData, fetchFooterData };
