/* eslint-disable jsx-a11y/no-static-element-interactions */
import { MobileMenuItemIC } from '@/lib/interface-types';
import Link from 'next/link';
import MobileSubmenu from './mobile-submenu';

const MobileNavigationItem = ({ groupItem }: MobileMenuItemIC) => {
  return (
    <div className="MobileSubmenu pl-5">
      <div className="flex flex-col mt-4">
        {groupItem?.featuredPagesFirstColumnCollection?.items && (
          <>
            {groupItem.secondaryTitle && (
              <Link
                href={groupItem.secondaryTitleLink || ''}
                className="text-brand-green mb-4"
              >
                {groupItem.secondaryTitle}
              </Link>
            )}
            <MobileSubmenu
              href={'/expertise/' + groupItem.slugForFirstColumn}
              title={groupItem.titleForFirstColumn}
              items={groupItem?.featuredPagesFirstColumnCollection?.items}
            />
          </>
        )}
        {groupItem?.featuredPagesSecondColumnCollection?.items && (
          <MobileSubmenu
            href={'/expertise/' + groupItem.slugForSecondColumn}
            title={groupItem.titleForSecondColumn}
            items={groupItem?.featuredPagesSecondColumnCollection?.items}
          />
        )}
        {groupItem?.featuredPagesThirdColumnCollection?.items && (
          <MobileSubmenu
            href={'/expertise/' + groupItem.slugForThirdColumn}
            title={groupItem.titleForThirdColumn}
            items={groupItem?.featuredPagesThirdColumnCollection?.items}
          />
        )}
      </div>
    </div>
  );
};

export default MobileNavigationItem;
