import { useOSContext } from '@/context/OSContext';
import HeaderCard from '@/ctf-components/header-card';
import { MenuItemIC } from '@/lib/interface-types';
import AddPlusIcon from '@/public/icons/add-plus-icon.svg';
import RemoveMinusIcon from '@/public/icons/remove-minus-icon.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import SubmenuList from '../submenu-list';

const NavigationItem = ({ groupItem, closeOtherMenus, isOpen }: MenuItemIC) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(isOpen);
  const [documentRef, setDocumentRef] = useState<Document | null>(null);
  const submenuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const context = useOSContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (submenuRef.current && buttonRef.current) {
        if (
          !submenuRef.current.contains(event.target as HTMLElement) &&
          !buttonRef.current.contains(event.target as HTMLElement)
        ) {
          setIsSubMenuOpen(false);
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setDocumentRef(document);
  }, []);

  const handleClick = () => {
    if (closeOtherMenus) {
      closeOtherMenus();
    }
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const substring = router.asPath.substring(1);
  const groupName = groupItem.internalTitle?.toLocaleLowerCase();
  const groupNameWords = groupName.split(' ');

  const isActiveLink =
    groupNameWords.length > 0 && substring.includes(groupNameWords[0]);
  const linkClasses = `uppercase text-base font-medium single-underline-animation ${
    isActiveLink ? 'text-brand-green ' : ''
  }`;

  let linkIcon = AddPlusIcon;
  if (isSubMenuOpen) {
    linkIcon = RemoveMinusIcon;
  } else if (!isSubMenuOpen) {
    linkIcon = AddPlusIcon;
  }

  return (
    <>
      <div className="flex flex-row items-center pr-4 xl:pr-10 last:pr-0 text-[1.0625rem] tracking-[0.0625rem]">
        {groupItem.groupLink ? (
          <Link className={`${linkClasses}`} href={groupItem.groupLink.urlPath}>
            {groupItem.groupName}
          </Link>
        ) : (
          <div
            className="uppercase text-base font-medium flex flex-row items-center cursor-pointer "
            ref={buttonRef}
            onClick={handleClick}
          >
            <span className={`${linkClasses}`}>{groupItem.groupName}</span>
            <Image
              src={linkIcon}
              alt={isSubMenuOpen ? 'Arrow up icon' : 'Arrow down icon'}
              width={20}
              height={20}
              className={`ml-3 ${context?.os !== 'Windows' && 'mb-[6px]'}`}
            />
          </div>
        )}
      </div>

      {documentRef &&
        !groupItem.groupLink &&
        createPortal(
          <div
            ref={submenuRef}
            className={`Submenu transition-all duration-700 transform bg-white shadow-md  pb-20 ${
              isSubMenuOpen ? 'translate-y-0' : ' -translate-y-[150%]'
            } z-20 bg-white px-12 xl:px-28 3xl:px-60 py-8 fixed top-24 w-full max-w-full right-0`}
          >
            <div className={`mx-auto max-w-[1500px] `}>
              {groupItem && groupItem.internalTitle === 'Industries' && (
                <h3
                  className={`capitalize transition-all duration-500 delay-700 opacity-0  ${
                    isSubMenuOpen && 'opacity-100'
                  } ${isSubMenuOpen ? 'translate-y-0' : '-translate-y-[-25%]'}`}
                >
                  <HeaderCard groupItem={groupItem} />
                </h3>
              )}
              <div className={`flex gap-16 xl:gap-20 2xl:gap-32`}>
                {groupItem?.featuredPagesFirstColumnCollection?.items && (
                  <SubmenuList
                    title={groupItem.titleForFirstColumn}
                    items={groupItem?.featuredPagesFirstColumnCollection?.items}
                    slug={groupItem.slugForFirstColumn}
                    groupName={groupItem.groupName}
                    isSubMenuOpen={isSubMenuOpen || false}
                  />
                )}
                {groupItem?.featuredPagesSecondColumnCollection?.items && (
                  <SubmenuList
                    title={groupItem.titleForSecondColumn}
                    items={
                      groupItem?.featuredPagesSecondColumnCollection?.items
                    }
                    slug={groupItem.slugForSecondColumn}
                    groupName={groupItem.groupName}
                    isSubMenuOpen={isSubMenuOpen || false}
                  />
                )}
                {groupItem?.featuredPagesThirdColumnCollection?.items && (
                  <SubmenuList
                    title={groupItem.titleForThirdColumn}
                    items={groupItem?.featuredPagesThirdColumnCollection?.items}
                    slug={groupItem.slugForThirdColumn}
                    groupName={groupItem.groupName}
                    isSubMenuOpen={isSubMenuOpen || false}
                  />
                )}
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default NavigationItem;
